import React, { useEffect } from "react";
import routes from "../constant/routes";
import { openSheet } from "../js/homeActions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../redux/reducers/authReducer";
import { $ } from "../js/homeActions";

export default function Navigation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, company } = useSelector((store) => store);

  useEffect(() => {
    dispatch(getUser({ authId: auth.id, authSession: auth.authSession }));
  }, []);

  const modules = [
    { href: routes.swap, title: "Exchange", icon: "exchange-alt" },
    { href: routes.wallet, title: "Wallet", icon: "home" },
  ];
  return (
    <>
      <div className={`header header-fixed header-dark header-logo-app ${window.location.pathname.replace("/", "")}`}>
        {[routes.coin].indexOf(window.location.pathname) > -1 && (
          <button
            data-back-button
            className="header-icon header-icon-1"
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            <i className="fa fa-arrow-left"></i>
          </button>
        )}
        <button
          onClick={(event) => {
            event.stopPropagation();
            openSheet(event, $);
          }}
          data-open="menu-bell"
          data-menu="menu-main"
          className="header-icon header-icon-2"
        >
          <i className="far fa-bell me-2"></i>
          <span>{auth.user.first_name}</span>
        </button>
      </div>
      {/* Footer Navigation */}
      <div id="footer-bar" className="footer-bar-1">
        {modules.map((link) => (
          <Link key={link.href} to={link.href} className={window.location.pathname !== link.href ? "" : "active-nav"}>
            <i className={`fa fa-${link.icon}`}></i>
            <span>{link.title}</span>
          </Link>
        ))}
        <button
          onClick={(event) => {
            event.stopPropagation();
            openSheet(event, $);
          }}
          data-open="menu-share"
        >
          <i className="fa fa-user-cog"></i>
          <span>Settings</span>
        </button>
      </div>
      {/* notifications */}
      <div id="menu-bell" className="menu menu-box-top"></div>
      {/* Settings Sheet */}
      <div id="menu-share" className="menu menu-box-bottom d-align-items-strech" style={{ minHeight: 270 }}>
        <div className="menu-title">
          <h1 className="my-0 py-0">Menu Options</h1>
        </div>
        <div className="divider divider-margins mt-3 mb-0"></div>
        <div className="content mt-0">
          <div className="list-group list-custom-small">
            <a href={`whatsapp://send?phone=${company.phone}`}>
              <i className="fab fa-whatsapp font-12 bg-whatsapp color-white shadow-l rounded-l"></i>
              <span>WhatsApp</span>
            </a>
            <a href={`mailto:${company.email}`}>
              <i className="fa fa-envelope font-12 bg-mail color-white shadow-l rounded-l"></i>
              <span>Email</span>
              <i className="fa fa-angle-right me-2"></i>
            </a>
            <Link to={routes.resetPassword}>
              <i className="fa fa-user-lock font-12 bg-primary color-white shadow-l rounded-l"></i>
              <span>Reset Password</span>
              <i className="fa fa-angle-right me-2"></i>
            </Link>
            <Link to={routes.signout}>
              <i className="fa fa-sign-out-alt font-12 bg-red-dark color-white shadow-l rounded-l"></i>
              <span>Logout</span>
              <i className="fa fa-angle-right me-2"></i>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
