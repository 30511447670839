import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance, num_format } from "../js/homeActions";
import { setWalletStatus } from "../redux/reducers/coinReducer";

export default function TrnxDetails() {
  const dispatch = useDispatch();
  const {
    auth,
    coin: { data: coin, coinState },
  } = useSelector((store) => store);
  const [trx, setTrx] = useState({});

  useEffect(async () => {
    const { data: response } = await axios({
      method: "post",
      data: { case: "getTrx", trxid: coinState.trxid, authId: auth.id, authSession: auth.authSession },
      ...axiosInstance,
    }).catch((e) => ({ data: { status: false, message: e.message } }));

    if (response.status) {
      setTrx(response.data);
    } else {
      dispatch(setWalletStatus(response.data));
    }
  }, []);
  return (
    <>
      <div id="TrnxDetails" className="menu menu-box-bottom" style={{ minHeight: 550 }}>
        <div className="content mt-0 w-100">
          {trx.id && (
            <div id="crypto-details" className="w-100">
              {parseInt(trx.status) === 1 && (
                <h1 className="text-center mt-4">
                  <figure>
                    <img
                      src="assets/images/icons/checkmark-done-circle-outline-svgrepo-com.svg"
                      style={{ width: "70px" }}
                    />
                  </figure>
                </h1>
              )}
              <h3 className="text-center mt-3 font-700">
                {trx.amount} {trx.coin}
              </h3>
              <p className="boxed-text-xl mb-0 opacity-70">
                <i className="fas fa-dollar-sign"></i>
                {num_format(parseFloat(trx.amount * trx.price).toFixed(coin.decimals))}
              </p>
              <p className="vcard-field boxed-text-xl mb-0 text-muted">
                <strong style={{ left: 0 }}>Status</strong>
                {parseInt(trx.status) === 1 && (
                  <small className="text-muted text-left float-left w-100">Completed</small>
                )}
                {parseInt(trx.status) !== 1 && (
                  <small className="text-muted text-left float-left w-100">{trx.remaining}</small>
                )}
              </p>
              <p className="vcard-field boxed-text-xl mb-0">
                <strong style={{ left: 0 }}>Explorer</strong>
                <small className="text-muted text-left float-left w-100">{trx.tx_no}</small>
              </p>
              {trx.address !== "INTERNAL_WALLET" && (
                <p className="vcard-field boxed-text-xl mb-0">
                  <strong style={{ left: 0 }}>Address</strong>
                  <small className="text-muted text-left float-left w-100">{trx.address}</small>
                </p>
              )}
              <p className="vcard-field boxed-text-xl mb-0">
                <strong style={{ left: 0 }}>Date</strong>
                <small className="text-muted text-left float-left w-100">{trx.date_created}</small>
              </p>
            </div>
          )}
          {!trx.id && (
            <div className="d-flex justify-content-center">
              <div className="spinner-border color-green-dark" style={{ borderWidth: 2 }} role="status"></div>
            </div>
          )}
        </div>
      </div>
      <div className="menu-hider menu-active"></div>
    </>
  );
}
