import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ToastAlert from "../components/ToastAlert";
import routes from "../constant/routes";
import { register } from "../redux/reducers/authReducer";
import { $ } from "../js/homeActions";

export default function SignupScreen() {
  const dispatch = useDispatch();
  const {
    company,
    auth: { authScreen: status },
  } = useSelector((store) => store);

  // Manage internal state of my formdata locally
  const [formdata, updateFormdata] = useState({
    name: null,
    email: null,
    password: null,
    password2: null,
  });

  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };

  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      dispatch(register(formdata));
    }
  };
  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content mb-0 pb-0">
          <div className="card mb-0  bg-chart" style={{ height: "100vh" }}>
            <ToastAlert status={status} />
            <div className="card-center mx-4">
              <div className="rounded-sm">
                <Link to={routes.index}>
                  <img src={company.logo_ref} width="80" className="float-end under-slider-btn me-3" />
                </Link>
                <div className="clearfix"></div>
                <form
                  autoComplete="off"
                  onSubmit={(event) => {
                    submitForm(event);
                  }}
                >
                  <div className="px-3 py-3">
                    <h1 className="mb-n1">Register</h1>
                    <p className="mb-5 font-11">Create an Accout. It's free.</p>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-user"></i>
                      <input
                        onChange={(event) => {
                          updateField(event);
                        }}
                        name="name"
                        type="text"
                        className="form-control focus-color validate-name"
                        id="form1a"
                        placeholder="Name"
                        required
                        disabled={status.loading}
                      />
                      <em>(required)</em>
                    </div>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-at"></i>
                      <input
                        onChange={(event) => {
                          updateField(event);
                        }}
                        type="email"
                        name="email"
                        className="form-control focus-color validate-name"
                        id="form1aa"
                        placeholder="Email"
                        required
                        disabled={status.loading}
                      />
                      <em>(required)</em>
                    </div>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-lock"></i>
                      <input
                        onChange={(event) => {
                          updateField(event);
                        }}
                        type="password"
                        name="password"
                        className="form-control focus-color validate-text"
                        id="form3a"
                        placeholder="Choose a Password"
                        required
                        disabled={status.loading}
                      />
                    </div>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-lock"></i>
                      <input
                        onChange={(event) => {
                          updateField(event);
                        }}
                        type="password"
                        name="password2"
                        className="form-control focus-color validate-text"
                        id="form3a1"
                        placeholder="Confirm your Password"
                        required
                        disabled={status.loading}
                      />
                      <em>(required)</em>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Link to={routes.signin} className="text-start d-block color-theme opacity-50 font-10">
                          Sign In Instead
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={status.loading}
                      className="back-button w-100 btn btn-full btn-m rounded-s bg-blue-dark font-700 text-uppercase mt-4"
                    >
                      <span className="me-3">Register</span>
                      {status && status.loading && (
                        <div className="spinner-border spinner-border-sm color-red-dark"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
