import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
//  Components
import Navigation from "../components/Navigation";
import SendCrypto from "../components/SendCrypto";
import ToastAlert from "../components/ToastAlert";
import RecieveCrypto from "../components/RecieveCrypto";
import CryptoChart from "../components/CryptoChart";
import { openSheet, $ } from "../js/homeActions";
import TrnxDetails from "../components/TrnxDetails";

import { fetchCoin, createWallet, setWalletStatus, resetCoin, setTrxId } from "../redux/reducers/coinReducer";
import { useSearchParams } from "react-router-dom";

export default function CoinScreen() {
  const [searchParams] = useSearchParams();
  const coinId = searchParams.get("coin");
  let { coin, auth, company } = useSelector((store) => store);
  let dispatch = useDispatch();

  // Anytime coinState changes, activate or deactivateh the panel
  useEffect(() => {
    if (coin.coinState.openPanel) {
      openSheet(coin.coinState.openPanel, $, () => {
        $(".page-content").css({ transform: "translateY(0px)" });
        dispatch(setWalletStatus({ openPanel: null }));
      });
    }
  }, [coin.coinState]);

  // Fetch coin data on page load
  useEffect(() => {
    dispatch(
      fetchCoin({
        authId: auth.id,
        authSession: auth.authSession,
        coinId,
        popMessage: false,
      })
    );

    // Fet coin data on 1 min intervals
    const interval = setInterval(() => {
      dispatch(
        fetchCoin({
          authId: auth.id,
          authSession: auth.authSession,
          coinId,
        })
      );
    }, 60 * 1000); //1 min

    return () => {
      clearInterval(interval);
      dispatch(resetCoin());
    };
  }, []);
  const __icon = {
    IN: { icon: "fa-arrow-down", color: "text-success" },
    OUT: { icon: "fa-arrow-up", color: "text-danger" },
  };
  return (
    <div id="page">
      <ToastAlert status={coin.status} reducer="coin" />
      <Navigation />
      <ScrollAnimation animateIn="slideInUp" animateOut="slideOutDown">
        <div className="page-content" style={{ height: "85vh" }}>
          <div style={{ height: 250 }} className="card mx-3 rounded-sm">
            <div className="mt-3 balance-section">
              <figure className="text-center mt-0">
                {coin.coinState.loaded && (
                  <img src={coin.data.logo || company.favicon} width={100} className="rounded-circle"></img>
                )}
                {!coin.coinState.loaded && (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border color-blue-dark" style={{ borderWidth: 7 }} role="status"></div>
                  </div>
                )}
                <h1 className=" mb-n1 mt-2">{coin.data.formatted_balance}</h1>
                <p className="font-10">
                  <b className="text-muted">{coin.data.formatted_dollar_value}</b>
                </p>
              </figure>
            </div>
            <div className="card-bottom mx-2">
              <p className=" mb-n1">{coin.data.name} Price</p>
              <p className="mb-2  opacity-50 under-heading font-12">
                <span>{coin.data.formatted_price} </span>
                {coin.data.percentage > 0 && <small className="text-success">+{coin.data.percentage}%</small>}
                {coin.data.percentage < 0 && <small className="text-danger">{coin.data.percentage}%</small>}
              </p>
            </div>
            <div className="card-bottom mx-3">
              <p className="text-end mb-0 font-8 font-500 opacity-50 ">
                <button>
                  <i
                    onClick={(event) => {
                      dispatch(setWalletStatus({ openPanel: "CryptoChart" }));
                    }}
                    className="fa fa-chart-line font-20"
                  ></i>
                </button>
              </p>
              <p className="font-8 mb-2 font-500 opacity-50  text-end">View {coin.data.name} Chart</p>
            </div>
          </div>
          {/* ----------------------------------- Create wallet button-------------------------- */}
          {!coin.wallet && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-around",
              }}
            >
              <button
                onClick={(event) => {
                  dispatch(
                    createWallet({
                      authId: auth.id,
                      authSession: auth.authSession,
                      coinId,
                    })
                  );
                }}
                disabled={coin.coinState.createWalletLodading}
                className="btn  btn-m btn-full mb-3 rounded-2 text-uppercase font-900 shadow-s bg-blue-dark"
              >
                Create {coin.data.name} wallet
                {coin.coinState.createWalletLodading && (
                  <div className="spinner-border spinner-border-sm text-black ms-3"></div>
                )}
              </button>
            </div>
          )}
          {/* ----------------------------------- send and recieve buttons -------------------------- */}
          {coin.wallet && (
            <div id="tab-group-1" className="content">
              <div className="tab-controls tabs-large mb-4">
                <a
                  className="btn py-2"
                  onClick={(event) => {
                    dispatch(setWalletStatus({ openPanel: "SendCrypto" }));
                  }}
                >
                  Send
                </a>
                <a
                  className="btn py-2"
                  onClick={(event) => {
                    dispatch(setWalletStatus({ openPanel: "RecieveCrypto" }));
                  }}
                >
                  Receive
                </a>
              </div>
              {/* ---------------------------Transactons--------------------------------- */}
              <div className="collapse show" id="tab-1">
                <div className="list-group list-custom-large">
                  {coin.transactions &&
                    coin.transactions.length > 0 &&
                    coin.transactions.map((transaction) => (
                      <a
                        key={transaction.tx_no}
                        onClick={(event) => {
                          dispatch(setTrxId(transaction.id));
                          dispatch(setWalletStatus({ openPanel: "TrnxDetails" }));
                        }}
                        className="coin-item"
                      >
                        <div className="w-15">
                          <i
                            className={`fa ${__icon[transaction.tx_type].icon} font-20 ${
                              __icon[transaction.tx_type].color
                            }`}
                          ></i>
                        </div>
                        <div className="w-80">
                          <p className="mb-1">
                            <b className="font-15 truncate">{transaction.tx_no}</b>
                            <small className=""> {transaction.amount}</small>
                          </p>
                          <p className="mt-2">
                            <span>
                              <small className="font-10">{transaction.date_created}</small>
                            </span>
                            <small> {transaction.dollar_value}</small>
                          </p>
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </ScrollAnimation>
      {coin.wallet && (
        <>
          {coin.coinState.openPanel == "SendCrypto" && <SendCrypto />}
          {coin.coinState.openPanel == "RecieveCrypto" && <RecieveCrypto />}
          {coin.coinState.openPanel == "TrnxDetails" && <TrnxDetails />}
        </>
      )}
      {coin.coinState.openPanel == "CryptoChart" && <CryptoChart />}
    </div>
  );
}
