import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $, num_format } from "../js/homeActions";
import { depositCoin, setWalletStatus, showStatus, fetchCoin } from "../redux/reducers/coinReducer";

export default function RecieveCrypto() {
  const dispatch = useDispatch();
  const {
    auth,
    coin: { data: coin, status },
  } = useSelector((store) => store);
  const [beenSent, setBeenSent] = useState(false);
  const [formdata, updateFormdata] = useState({
    authId: auth.id,
    authSession: auth.authSession,
    coinId: coin.symbol,
    amount: 0,
    value: 0,
  });
  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      amount: event.target.value / coin.price,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };
  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      setBeenSent(true);
      // Dispatch the form data to an api
      dispatch(depositCoin(formdata));
    }
  };

  useEffect(() => {
    if (beenSent && status.status && status.action === "deposit-coin") {
      $("#crypto-details").swapDiv($("#crypto-confirm"), () => {
        setTimeout(() => {
          $(".page-content").css({ transform: "translateY(0px)" });
          dispatch(setWalletStatus({ openPanel: null }));
          dispatch(
            fetchCoin({
              authId: auth.id,
              authSession: auth.authSession,
              coinId: formdata.coinId,
              popMessage: false,
            })
          );
        }, 20000); // 20 sec
      });
    }
  }, [beenSent, status, dispatch]);
  return (
    <>
      <div id="RecieveCrypto" className="menu menu-box-bottom" style={{ height: "auto" }}>
        <div id="crypto-details" className="w-100">
          <h1 className="text-center mt-4">
            <figure>
              <img alt="QR Code" className="bg-white" src={coin.qr_code} style={{ width: "150px" }} />
            </figure>
          </h1>
          <h3 className="text-center mt-3 font-700">Receive {coin.name}</h3>
          <p className="boxed-text-xl mb-0 opacity-70">Please send only {coin.symbol} to this address.</p>
          <p className="vcard-field boxed-text-xl mb-0 text-muted">
            <strong style={{ left: 0 }}>Network</strong>
            <small className="text-muted text-left float-left w-100">{coin.network}</small>
          </p>
          <p className="vcard-field boxed-text-xl mb-0">
            <strong style={{ left: 0 }}> {coin.name} Deposit Address</strong>
            <small className="text-muted text-left float-left w-100">{coin.address}</small>
            <button
              onClick={() => {
                $.copyText(coin.address, () => {
                  dispatch(showStatus({ data: { status: 1, message: "Copied" } }));
                });
              }}
            >
              <i className="fa fa-copy opacity-100 py-2"></i>
            </button>
          </p>
          <p className="vcard-field boxed-text-xl mb-0">
            <strong style={{ left: 0 }}> Amount of {coin.symbol} to Deposit</strong>
            <small className="text-muted text-left float-left w-100">
              {num_format(formdata.amount.toFixed(coin.decimals))}
            </small>
            <button
              onClick={() => {
                $.copyText(formdata.amount, () => {
                  dispatch(showStatus({ data: { status: 1, message: "Copied" } }));
                });
              }}
            >
              <i className="fa fa-copy opacity-100 py-2"></i>
            </button>
          </p>
          <form className="w-100" onSubmit={(event) => submitForm(event)}>
            <div className="row w-100 pt-3">
              <div className="col-6">
                <div className="input-style no-borders ps-3 ps-5 validate-field mb-4">
                  <input
                    type="number"
                    required
                    className="form-control validate-password"
                    id="value"
                    step="any"
                    min="0.00000001"
                    placeholder="Enter Amount"
                    name="value"
                    disabled={status.loading}
                    onChange={(event) => {
                      updateField(event);
                    }}
                  />
                  <em className="opacity-100" style={{ left: 20 }}>
                    <i className="fas fa-dollar-sign"></i>
                  </em>
                </div>
              </div>
              <div className="col-6">
                <button
                  disabled={status.loading}
                  className="btn close-menu w-100 btn-m color-green-dark border-green-dark font-800 text-uppercase"
                >
                  Copied
                  {status.loading && <div className="spinner-border spinner-border-sm color-green-dark ms-2"></div>}
                  {!status.loading && <i className="fa fa-arrow-right ms-2"></i>}
                </button>
              </div>
            </div>
          </form>
        </div>
        {beenSent && (
          <div id="crypto-confirm" className="content mt-0 w-100" style={{ display: "none" }}>
            <h1 className="text-center mt-4">
              <div className="spinner-border color-green-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </h1>
            <h3 className="text-center mt-3 font-700">Waiting for Deposit</h3>
            <p className="boxed-text-xl mt-3 opacity-70">
              You can close this window while we wait for the wallet to recieve funds.
              <br /> You would be notified once the deposit has been completed.
            </p>
          </div>
        )}
      </div>
      <div className="menu-hider menu-active"></div>
    </>
  );
}
