import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { $ } from "../js/homeActions";
import { getUser } from "../redux/reducers/authReducer";

export default function NotFound(props) {
  const { company, auth } = useSelector((store) => store);
  let data = (props.icon && $.extend(props, {})) || {
    title: auth.user.statusText,
    text: `Dear ${auth.user.first_name} ${auth.user.last_name}, your account has been ${auth.user.statusText}. \r\n Please concact support for further clarifications.`,
    icon: "info fa-breathing text-warning",
    url: `mailto:${company.email}`,
    support: true,
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.id) dispatch(getUser({ authId: auth.id, authSession: auth.authSession }));
  }, []);

  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content">
          <div className="card" style={{ height: 600 }}>
            <div className="card-center">
              <h1 className="glow-red fa-7x text-center text-black">
                <i className={`fa fa-${data.icon}`}></i>
              </h1>
              <h1 className="text-center text-uppercase font-30 pt-3">{data.title}</h1>
              <p className="boxed-text-xl pt-3">
                {data.text}
                <br />
                <br />
                <br />
                {!data.support && data.title != "forbidden" && (
                  <span className="text-grey">
                    We're searching for our server guy, he has some explaining to do! We'll be back shortly.
                  </span>
                )}
              </p>
              {!data.support && data.title != "forbidden" && (
                <Link
                  to={data.url}
                  className="back-button btn btn-m text-uppercase font-700 rounded-s btn-center-m bg-blue-dark"
                >
                  Return
                </Link>
              )}
              {data.support && (
                <a
                  href={data.url}
                  className="back-button btn btn-m text-uppercase font-700 rounded-s btn-center-m bg-blue-dark"
                >
                  Contact Support
                </a>
              )}
            </div>
          </div>
          <div data-menu-load="menu-footer.html"></div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
