import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWalletStatus, transferToken, fetchCoin } from "../redux/reducers/coinReducer";
import { $, num_format } from "../js/homeActions";
import SendCode from "./SendCode";

export default function SendCrypto() {
  const dispatch = useDispatch();
  const {
    auth,
    coin: { data: coin, status },
  } = useSelector((store) => store);
  const [beenSent, setBeenSent] = useState(false);
  const [network, setnetwork] = useState(0);
  const [formdata, updateFormdata] = useState({
    authId: auth.id,
    authSession: auth.authSession,
    coinId: coin.symbol,
    amount: "",
    value: 0,
    address: "",
    code: 0,
  });

  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    _formdata.value = _formdata.amount * coin.price;
    updateFormdata(_formdata);
  };

  const validate = (event, callback) => {
    event.preventDefault();
    let form;
    if (event.type == "click") {
      form = $(event.currentTarget).closest("form");
    } else {
      form = $(event.currentTarget);
    }
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      if (typeof callback == "function") callback();
    } else {
      form.find("input:not([name=code])[value='']").first().focus();
    }
  };
  // OnSubmit of my form
  const submitForm = (event) => {
    validate(event, () => {
      setBeenSent(true);
      // Dispatch the form data to an api
      dispatch(transferToken(formdata));
    });
  };
  useEffect(() => {
    if (beenSent && status.status && status.action === "transfer-token") {
      $("#crypto-details").swapDiv($("#crypto-confirm"), () => {
        setTimeout(() => {
          $(".page-content").css({ transform: "translateY(0px)" });
          dispatch(setWalletStatus({ openPanel: null }));
          dispatch(
            fetchCoin({
              authId: auth.id,
              authSession: auth.authSession,
              coinId: formdata.coinId,
              popMessage: false,
            })
          );
        }, 20000); // 20 sec
        setTimeout(() => {
          setnetwork(1);
        }, 10000); // 10 sec
      });
    }
  }, [beenSent, status, dispatch]);
  return (
    <>
      <div id="SendCrypto" className="menu menu-box-bottom px-3" style={{ height: 300 }}>
        <div id="crypto-details" className="mt-0 w-100">
          <form
            onSubmit={(event) => {
              submitForm(event);
            }}
            className="w-100"
          >
            <div id="enterDetails" className="panel">
              <div className="menu-title">
                <h1 className="my-0 py-0 text-center">Send {coin.symbol}</h1>
              </div>
              <div className="input-style no-borders has-icon validate-field mb-4">
                <i className="fa fa-external-link-alt"></i>
                <input
                  value={formdata.address}
                  type="text"
                  required
                  className="form-control validate-email"
                  name="address"
                  disabled={status.loading}
                  placeholder="Wallet Address"
                  onChange={(event) => {
                    updateField(event);
                  }}
                />
                <em>({coin.network})</em>
              </div>
              <div className="input-style no-borders has-icon validate-field mb-4">
                <i className="fa fa-info-circle"></i>
                <input
                  value={formdata.amount}
                  id="crypto-amount"
                  type="number"
                  step="any"
                  min="0.00001"
                  max={coin.value}
                  required
                  className="form-control validate-password"
                  name="amount"
                  placeholder="Amount"
                  disabled={status.loading}
                  onClick={(event) => {
                    $(event.target)[0].customEvent = event;
                  }}
                  onChange={(event) => {
                    $(event.target)[0].customEvent = event;
                    updateField(event);
                  }}
                  onKeyUp={(event) => {
                    if (event.target.valueAsNumber > coin.balance)
                      setTimeout(() => {
                        $(event.target).val(coin.balance);
                        updateField(event);
                      }, 100);
                  }}
                />
                <button
                  type="button"
                  className="input-max"
                  onClick={() => {
                    var el = document.getElementById("crypto-amount");
                    $(el).val(coin.balance).click();
                    updateField($(el)[0].customEvent);
                  }}
                >
                  <b className="badge bg-grey">Max</b>
                </button>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="vcard-field">
                    <strong>Dollar Equivalence</strong>
                    <span className="text-muted">{num_format(formdata.value)}</span>
                    <i className="fa fa-dollar-sign color-green1-dark"></i>
                  </div>
                </div>
                <div className="col-6">
                  <button
                    disabled={status.loading}
                    type="button"
                    className="btn w-100 btn-m shadow-l mt-4 font-600 bg-blue-dark"
                    onClick={(event) => {
                      validate(event, () => {
                        $("#enterDetails").swapDiv($("#authorize"));
                      });
                    }}
                  >
                    Authorize
                  </button>
                </div>
              </div>
            </div>
            <div id="authorize" className="panel py-3" style={{ display: "none" }}>
              <div className="row mb-0">
                <h1 className="my-0 py-0 text-center">Authorize Transaction</h1>
                <p className="text-center">
                  <small>
                    <i>
                      You're about to withdraw ${num_format(formdata.value)} worth of {coin.symbol} from your wallet
                    </i>
                  </small>
                </p>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="input-style no-borders has-icon validate-field">
                    <i className="fa fa-code"></i>
                    <input
                      type="number"
                      className="form-control validate-text focus-color"
                      name="code"
                      required
                      value={formdata.code}
                      disabled={status.loading}
                      placeholder="Enter the code"
                      onChange={(event) => updateField(event)}
                    />
                    <SendCode status={auth.sendCode} action="send-crypto" />
                  </div>
                </div>
                <div className="col-12">
                  <button disabled={status.loading} className="btn w-100 btn-m shadow-l mt-4 font-600 bg-blue-dark">
                    Withdraw
                    {status.loading && <div className="spinner-border spinner-border-sm text-default ms-2"></div>}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {beenSent && (
          <div id="crypto-confirm" className="content mt-3 w-100" style={{ display: "none" }}>
            <h1 className="text-center mt-4">
              <div className="spinner-border color-red-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </h1>
            <h3 className="text-center mt-3 font-700">Processing Network Confirmations...</h3>
            <p className="boxed-text-xl mt-3 opacity-70">
              Processing... {network} of 15 network confirmations.
              <br />
              <br /> You may close the window while we send your money accross.
              <br /> You would be notified once the withdrawal is completed.
            </p>
          </div>
        )}
      </div>
      <div className="menu-hider menu-active"></div>
    </>
  );
}
