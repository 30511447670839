import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useDispatch, useSelector } from "react-redux";
import SendCode from "../components/SendCode";
import ToastAlert from "../components/ToastAlert";
import routes from "../constant/routes";
import { confirmEmail } from "../redux/reducers/authReducer";
import { $ } from "../js/homeActions";
import { Link } from "react-router-dom";

export default function ConfirmEmailScreen() {
  const dispatch = useDispatch();
  const {
    auth,
    company,
    auth: { authScreen: status },
  } = useSelector((store) => store);

  const [formdata, updateFormdata] = useState({ code: null });

  // Update the formdata state locally
  const updateField = (event) => {
    let _formdata = {
      ...formdata,
      [event.target.name]: event.target.value,
    };
    updateFormdata(_formdata);
  };

  // OnSubmit of my form
  const submitForm = (event) => {
    event.preventDefault();
    let form = $(event.currentTarget);
    // Prevent submit when required fields are empty
    if (form[0].checkValidity()) {
      // Dispatch the form data to an api
      dispatch(
        confirmEmail({
          ...formdata,
          authId: auth.id,
          authSession: auth.authSession,
        })
      );
    }
  };

  useEffect(() => {
    if (status.status && auth.user.status) {
      setTimeout(() => {
        window.location = routes.wallet;
      }, 2100);
    }
  }, [status]);

  // console.log("here");
  return (
    <div id="page">
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutRight">
        <div className="page-content mb-0 pb-0">
          <div className="card mb-0 bg-chart" style={{ height: "100vh" }}>
            {status && status.message && <ToastAlert status={status} />}
            <div className="card-center mx-4">
              <div>
                <Link to={routes.index}>
                  <img src={company.logo_ref} width="80" className="float-end under-slider-btn me-3" />
                </Link>
                <div className="clearfix"></div>
                <form
                  autoComplete="off"
                  onSubmit={(event) => {
                    submitForm(event);
                  }}
                >
                  <div className="py-5 px-3">
                    <h1>Confirm Your Email</h1>
                    <p className="mb-2 font-11">
                      Click on the "Send Code" button below to recieve your email activation code.
                    </p>
                    <div className="input-style no-borders has-icon validate-field">
                      <i className="fa fa-code"></i>
                      <input
                        type="number"
                        required
                        name="code"
                        className="form-control validate-text focus-color"
                        placeholder="Enter the Code sent to you"
                        disabled={status.loading}
                        onChange={(event) => {
                          updateField(event);
                        }}
                      />
                      <em>(required)</em>
                      <SendCode action="confirm-email" status={auth.sendCode} />
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Link to={routes.signout} className="text-start d-block color-theme opacity-50 font-10">
                          Reset Process
                        </Link>
                      </div>
                    </div>
                    <button
                      type="submit"
                      disabled={status.loading}
                      className="back-button btn w-100 btn-full btn-m rounded-s bg-blue-dark font-700 text-uppercase mb-3 mt-4"
                    >
                      Proceed
                      {status && status.loading && (
                        <div className="spinner-border spinner-border-sm color-red-dark"></div>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
