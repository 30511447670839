import { takeLatest } from "redux-saga/effects";
import {
  sendSeed,
  getUserData,
  sendUserData,
  authenticateUser,
  emailConfirmation,
  sendResetPassword,
  sendCodeFromServer,
  confirmResetPasswordAccount,
} from "./saga/authSaga";
import { requestCompany } from "./saga/companySaga";
import { processSwapAssets, getExchangeTransactions } from "./saga/swapSaga";
import {
  processFetchCoin,
  processGetWallet,
  processCreateWallet,
  processDepositCoin,
  processTransferToken,
} from "./saga/walletSaga";

export default function* watcherSaga() {
  yield takeLatest("auth/sendSeed", sendSeed);
  yield takeLatest("auth/getUser", getUserData);
  yield takeLatest("auth/register", sendUserData);
  yield takeLatest("auth/login", authenticateUser);
  yield takeLatest("coin/fetchCoin", processFetchCoin);
  yield takeLatest("auth/sendCode", sendCodeFromServer);
  yield takeLatest("company/getCompany", requestCompany);
  yield takeLatest("swap/swapAssets", processSwapAssets);
  yield takeLatest("wallet/getWallet", processGetWallet);
  yield takeLatest("coin/depositCoin", processDepositCoin);
  yield takeLatest("auth/confirmEmail", emailConfirmation);
  yield takeLatest("auth/resetPassword", sendResetPassword);
  yield takeLatest("coin/createWallet", processCreateWallet);
  yield takeLatest("coin/transferToken", processTransferToken);
  yield takeLatest("swap/getExchangeTransactions", getExchangeTransactions);
  yield takeLatest(
    "auth/confirmResetPasswordAccount",
    confirmResetPasswordAccount
  );
}
