import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import routes from "../constant/routes";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { getWallet, setLoading } from "../redux/reducers/walletReducer";
import ToastAlert from "../components/ToastAlert";

export default function WalletScreen() {
  const dispatch = useDispatch();
  const { wallet, auth } = useSelector((store) => store);

  useEffect(() => {
    const { id, authSession } = auth;
    dispatch(getWallet({ authId: id, authSession }));
    const interval = setInterval(() => {
      dispatch(getWallet({ authId: id, authSession }));
    }, 60 * 1000); // 1 min
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div id="page">
      <ToastAlert status={wallet.status} reducer="wallet" />
      <Navigation title="Wallet" />
      <ScrollAnimation animateIn="slideInRight" animateOut="slideOutDown">
        <div className="page-content mt-1" style={{ height: "85vh" }}>
          <div style={{ height: 250 }} className="card mx-3 rounded-sm">
            <div className="mt-3 balance-section">
              <figure className="text-center top-50">
                <h1 className=" mb-n1">
                  {wallet.currency}
                  {wallet.balance}
                </h1>
                <p className="font-12">Total Balance</p>
                <img src="assets/images/security-svgrepo-com.svg" width={100} alt="wallet" />
              </figure>
            </div>
            <div className="card-bottom">
              <div className="row mb-0">
                <div className="col-6">
                  <p className="text-start mb-0 font-8 font-500 opacity-50 ">
                    <i className="fa fa-wifi font-20 text-danger rotate-45"></i>
                  </p>
                  <p className="font-8 mb-2 font-500 opacity-50 text-danger text-start">Secured Wallet</p>
                </div>
                <div className="col-6">
                  <p className="text-end mb-0 font-8 font-500 opacity-50 ">
                    <button
                      onClick={() => {
                        const { id, authSession } = auth;
                        dispatch(setLoading());
                        dispatch(getWallet({ authId: id, authSession }));
                      }}
                    >
                      <i className="fas me-3 fa-redo-alt text-danger font-20"></i>
                    </button>
                  </p>
                  <p className="font-8 mb-2 font-500 opacity-50 text-danger text-end">Reload Wallet</p>
                </div>
              </div>
            </div>
          </div>
          <div id="tab-group-1" className="content">
            <div data-bs-parent="#tab-group-1" className="collapse show" id="tab-1">
              {!wallet.assets ||
                wallet.assets.length === 0 ||
                (wallet.status.loading && (
                  <div className="d-flex justify-content-center mb-3">
                    <div className="spinner-border text-info" style={{ borderWidth: 7 }} role="status"></div>
                  </div>
                ))}
              <div className="list-group list-custom-large">
                {wallet.assets &&
                  wallet.assets.length > 0 &&
                  wallet.assets.map((coin) => (
                    <Link key={coin.coin_id} to={`${routes.coin}?coin=${coin.symbol}`} className="coin-item">
                      <div className="w-20">
                        <i className="fab">
                          <img src={coin.logo}></img>
                        </i>
                      </div>
                      <div className="w-80">
                        <p className="mb-1">
                          <b className="font-15">{coin.name}</b>
                          <small className="">{coin.formatted_balance}</small>
                        </p>
                        <p className="mt-2">
                          <span>
                            <small className="font-10">
                              {wallet.currency}
                              {coin.formatted_price}
                            </small>
                            {coin.percentage > 0 && <small className="text-success ml-2">+{coin.percentage}%</small>}
                            {coin.percentage < 0 && <small className="text-danger ml-2">{coin.percentage}%</small>}
                          </span>
                          <small>{coin.formatted_dollar_value}</small>
                        </p>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
